<template>
  <tbody v-if="Object.keys(form).length">
    <tr class="text-right">
      <td colspan="4" class="text-right font-weight-bold h6">Ara Toplam</td>
      <td colspan="2" class="text-right font-weight-bold h6">{{ form.sepet_toplam.ara_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
    <tr class="text-right">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">KDV Toplam</td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">{{ form.sepet_toplam.kdv_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
    <tr class="text-right" v-if="form.sepet_toplam.kargo_toplam > 0">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">Kargo Toplam</td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">{{ form.sepet_toplam.kargo_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
    <tr class="text-right" v-if="form.sepet_toplam.kargo_ekstra_toplam > 0">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">Teslimat Ücreti</td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">{{ form.sepet_toplam.kargo_ekstra_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
    <tr class="text-right" v-if="form.sepet_toplam.indirim_toplam > 0">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">İndirim Toplam ({{ form.indirim_kuponu.kod }})</td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">{{ form.sepet_toplam.indirim_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
    <tr class="text-right">
      <td colspan="4" class="text-right font-weight-bold h5 border-0 text-primary">GENEL TOPLAM</td>
      <td colspan="2" class="text-right font-weight-bold h5 border-0 text-primary">{{ form.sepet_toplam.genel_toplam | cur }} {{ form.kur_turu }}</td>
    </tr>
  </tbody>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
