<template>
  <div v-if="form != null">
    <div class="d-flex w-100 justify-content-between">
      <b-card no-body class="rounded-0 w-50">
        <b-card-header class="bg-white font-weight-bold"> Firma Bilgileri </b-card-header>
        <b-card-body>
          <div class="int-kargo-fisi" v-if="Object.keys(firma).length">
            <div class="int-kargo-desc">
              <div class="d-flex">
                <h6 class="font-weight-bold">{{ firma.firma_adi }}</h6>
              </div>
              <div class="d-flex">
                <span>{{ firma.yetkili_ad_soyad }}</span>
              </div>
              <div class="d-flex">
                <span>
                  {{ firma.adres }}
                </span>
              </div>
              <div class="d-flex">
                <span>{{ firma.telefon }}</span>
              </div>
              <div class="d-flex">
                <span>{{ firma.e_mail }}</span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
      <b-card no-body class="rounded-0 w-50">
        <b-card-header class="bg-white font-weight-bold"> Sipariş Bilgileri </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="6">
              <div class="d-flex justify-content-between align-items-center pb-2">
                <span>Sipariş Numarası</span>
                <span class="font-weight-bold">{{ form.k_no }}</span>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-2">
                <span>Üye Adı Soyadı</span>
                <span class="font-weight-bold">{{ form.uye_ad }} {{ form.uye_soyad }} </span>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-2">
                <span>Sipariş Tarih Saat</span>
                <span class="font-weight-bold">{{ form.tarih | momentFull }}</span>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-2">
                <span>Ödeme Tipi</span>
                <span class="font-weight-bold">
                  {{ form.odeme_yontemi == 'kapida_odeme' ? 'Kapıda Ödeme' : form.odeme_yontemi == 'havale_eft' ? 'Banka Havalesi' : 'Kredi Kartı Ödeme' }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <b-row v-if="form.adres_bilgileri.kargo != null || form.adres_bilgileri.fatura != null">
      <b-col cols="12" md="6">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold"> Teslimat Bilgileri </b-card-header>
          <b-card-body v-if="form.adres_bilgileri">
            <AdresItem :item="form.adres_bilgileri.kargo" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold"> Fatura Bilgileri </b-card-header>
          <b-card-body v-if="form.adres_bilgileri">
            <AdresItem :item="form.adres_bilgileri.fatura" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between py-4">
      <b-card no-body class="rounded-0 w-50">
        <b-card-header class="bg-white font-weight-bold"> Göndirici Bilgileri </b-card-header>
        <b-card-body v-if="form.adres_bilgileri.gonderi" class="p-0">
          <table class="table table-striped table-borderless table-sm">
            <tbody>
              <tr>
                <td class="font-weight-bold">Ad Soyad :</td>
                <td>
                  {{ form.adres_bilgileri.gonderi.ad }}
                  {{ form.adres_bilgileri.gonderi.spyad }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Telefon :</td>
                <td>
                  {{ form.adres_bilgileri.gonderi.telefon }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">E Posta :</td>
                <td>
                  {{ form.adres_bilgileri.gonderi.e_mail }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
      <b-card no-body class="rounded-0 w-50">
        <b-card-header class="bg-white font-weight-bold"> Alıcı Bilgileri </b-card-header>
        <b-card-body v-if="form.adres_bilgileri.alici" class="p-0">
          <table class="table table-striped table-borderless table-sm">
            <tbody>
              <tr>
                <td class="font-weight-bold">Ad Soyad :</td>
                <td>
                  {{ form.adres_bilgileri.alici.ad }}
                  {{ form.adres_bilgileri.alici.spyad }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Telefon :</td>
                <td>
                  {{ form.adres_bilgileri.alici.telefon }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">E Posta :</td>
                <td>
                  {{ form.adres_bilgileri.alici.e_mail }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Adres :</td>
                <td>
                  {{ form.adres_bilgileri.alici.adres }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold">Sipariş Notu</b-card-header>
          <b-card-body>
            {{ form.sepet_notu }}
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="form.adres_bilgileri.alici.aciklama">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold"> Kart Üzerine Yazılacak Not </b-card-header>
          <b-card-body>
            {{ form.adres_bilgileri.alici.aciklama }}
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold d-flex justify-content-between align-items-center">
            <span>Sipariş Ürünleri</span>
            <b-button-group>
              <b-button class="printButtonVisible mr-2" squared size="sm" variant="info" @click="sabitKuraCevir = !sabitKuraCevir"> Sabit Kura Cevir </b-button>
              <b-button class="printButtonVisible" squared size="sm" variant="info" @click="handlerPrint"> Yazdır </b-button>
            </b-button-group>
          </b-card-header>
          <b-card-body>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-left font-weight-bold h6">#Ürün</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Fiyat</th>
                  <th scope="col" class="text-center font-weight-bold h6" width="15%">Miktar</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Kdv</th>
                  <th scope="col" class="text-right font-weight-bold h6" width="15%">Tutar</th>
                </tr>
              </thead>
              <tbody v-if="(form.sepet_toplam && form.kur_turu == form.secilen_kur) || sabitKuraCevir">
                <UrunSabitItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" :noimg="true" />
              </tbody>
              <tbody v-else-if="(form.kur_sepet_toplam && form.kur_turu != form.secilen_kur) || !sabitKuraCevir">
                <UrunKurItem v-for="(urun, i) in form.sepet_urunler" :key="i" :urun="urun" :noimg="true" />
              </tbody>
              <UrunSabitToplam :form="form" v-if="form.kur_turu == form.secilen_kur || sabitKuraCevir" />
              <UrunKurToplam :form="form" v-else />
            </table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <table class="w-100">
      <tr>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { useRouter } from '@/libs/utils';
import store from '@/store';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import AdresItem from './component/AdresItem.vue';
import UrunSabitItem from './component/UrunSabitItem.vue';
import UrunKurItem from './component/UrunKurItem.vue';
import UrunSabitToplam from './component/UrunSabitToplam.vue';
import UrunKurToplam from './component/UrunKurToplam.vue';
export default defineComponent({
  components: {
    AdresItem,
    UrunSabitItem,
    UrunKurItem,
    UrunSabitToplam,
    UrunKurToplam,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.sabitKuraCevir = ref(false);
    expo.form = ref(null);
    expo.test = ref(null);
    expo.firma = ref({});

    onMounted(async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        await store.dispatch('firmaBilgiGetir').then((res) => {
          if (res.data.data !== null) {
            expo.firma.value = res.data.data;
          }
        });
        await store.dispatch('eTicSiparisFindOne', k_no).then((res) => {
          console.log('res');
          console.log(res.adres_bilgileri);
          expo.form.value = res;
        });
      }

      if (Object.keys(expo.firma.value).length > 0 && Object.keys(expo.form.value).length > 0) window.print();
    });

    expo.handlerPrint = () => {
      window.print();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
@media print {
  .printButtonVisible {
    display: none;
  }
  @page {
    size: A4;
  }
}
.int-kargo-fisi {
  .int-kargo-title {
    border-bottom: 4px solid rgb(60, 60, 60);
  }
}
</style>
