<template>
  <tr>
    <td width="350px">
      <div class="d-flex">
        <div class="item-img pr-2">
          <b-img
            thumbnail
            :src="urun.gorseller | productImage"
            width="100"
            v-if="!noimg"
          />
        </div>
        <div>
          <h6 class="font-weight-bold">{{ urun.baslik }}</h6>
          <span class="d-block font-weight-light">
            Ürün Kodu :{{ urun.urun_kodu }}
          </span>
          <span
            class="d-block font-weight-light text-primary"
            v-if="urun.teslimat_tarih && urun.teslimat_saat"
          >
            Teslimat :{{ urun.teslimat_tarih }} [ {{ urun.teslimat_saat }}]
          </span>
          <span
            class="d-block font-weight-light"
            v-if="urun.select_variant.length > 0"
          >
            <span v-for="(vars, index) in urun.select_variant" :key="index">
              {{ vars.baslik["tr"].baslik }} :
              {{ vars.secilen.icerik["tr"].baslik }}
            </span>
          </span>
          <span class="font-weight-light" v-if="urun.select_renk">
            Renk:
            <span :style="`color:${renkNedir(urun.select_renk).renk}`">
              {{ renkNedir(urun.select_renk).icerik["tr"].baslik }}
            </span>
          </span>
        </div>
      </div>
    </td>
    <td style="vertical-align: middle" class="text-right">
      <font>
        <b class="d-block"
          >{{ urun.kur_satis_fiyat | cur }} {{ urun.secilen_kur }}</b
        >

        <span class="text-info" style="font-size: 14px">
          <b>{{ urun.ucretsiz_kargo ? "Ücretsiz Kargo" : "" }}</b>
        </span>
      </font>
    </td>
    <td style="vertical-align: middle" class="text-center">
      <b>{{ urun.miktar }}</b>
    </td>
    <td style="vertical-align: middle" class="text-right">
      <b
        >{{ urun.kdv_oran }}
        {{ urun.kdv_turu == "haric" ? "%" : "KDV Dahil" }}</b
      >
    </td>
    <td align="right" style="vertical-align: middle">
      <font>
        <b>{{ toplamHesapla | cur }} {{ urun.secilen_kur }}</b>
      </font>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, toRefs } from "@vue/composition-api";

export default defineComponent({
  props: {
    urun: {
      type: Object,
      required: true,
    },
    noimg: [Boolean],
  },
  setup(props) {
    const expo = {};
    const { urun } = toRefs(props);
    expo.changeCount = (event) => {
      if (event) {
        urun.value.miktar++;
      } else {
        if (urun.value.miktar > 1) urun.value.miktar--;
      }
    };

    expo.renkNedir = computed(() => {
      return (val) => {
        return urun.value.renkler.find((x) => x.k_no == val);
      };
    });

    expo.toplamHesapla = computed(() => {
      if (urun.value.kdv_turu == "haric") {
        const fiyat =
          parseFloat(urun.value.kur_satis_fiyat) * parseInt(urun.value.miktar);
        const kdv = parseFloat(urun.value.kdv_oran);
        const total = (fiyat * kdv) / 100;

        return fiyat + total;
      } else {
        return urun.value.kur_satis_fiyat * urun.value.miktar;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
